(function(){
	'use strict';

	angular.module('aerosApp')
		.directive('progressBar', [function () {
			return {
				restrict: 'E',
				scope: {
					total: "@",
					completed: "@",
					failed: "@",
					remaining: "@",
					showDetailedResult: "&"
				},
				templateUrl: '/static/templates/directive/progress-bar.html',
				controller: ['$scope', function($scope){
					$scope.width = function(amount, total){
						var percent = amount * 100 / total;
						return { width: percent + '%' };
					};
				}]
			};
		}]);
})();